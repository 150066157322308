<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile is-variable is-2 mb-0">
        <div class="column is-12">
          <b-input-validation
            v-model="myPermission.label"
            :disabled="disabledItems || !!permission.id"
            rules="required|min:2"
            maxlength="50"
            :label="$tc('permission', 1)"
            :placeholder="placeholders.permission.name"
          />
        </div>
        <div class="column is-6-tablet is-12">
          <b-input-validation
            v-model="myPermission.description"
            :disabled="disabledItems"
            rules="required|min:2"
            rows="2"
            has-counter
            input-type="textarea"
            maxlength="255"
            :label="$t('description')"
            :placeholder="placeholders.permission.display"
          />
        </div>
        <!-- <div class="column is-6-tablet is-12">
          <b-input-validation
            v-model="myPermission.description"
            :disabled="disabledItems"
            rules="required|min:2"
            rows="3"
            input-type="textarea"
            maxlength="255"
            has-counter
            :label="$t('description')"
            :placeholder="placeholders.permission.description"
          />
        </div> -->
      </div>
      <b-table      
        v-if="renderComponent && (myPermission.roles && myPermission.roles.length)" 
        :data="myPermission.roles || []" 
        style="min-height: 100px"        
        hoverable
      > 
        <b-table-column
          v-slot="props"
          field="label"
          :label="label.role"
          header-class="sticky-header"
        >
          <b-tooltip
            position="is-right"
            :label="props.row.description"
            type="is-primary is-light"
            multilined
          >
            {{ props.row.label }}
          </b-tooltip>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="create"
          :label="label.create"
          width="80"
          centered
          header-class="sticky-header"
        >
          <b-checkbox          
            v-model="props.row.create"           
            class="ml-3"
            :disabled="disabledItems"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="read"
          :label="label.read"
          width="80"
          centered
          header-class="sticky-header"
        >
          <b-checkbox          
            v-model="props.row.read"           
            class="ml-3"
            :disabled="disabledItems"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="update"
          :label="label.update"
          width="80"
          centered
          header-class="sticky-header"
        >
          <b-checkbox          
            v-model="props.row.update"           
            class="ml-3"
            :disabled="disabledItems"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="delete"
          :label="label.delete"
          width="80"
          centered
          header-class="sticky-header"
        >
          <b-checkbox          
            v-model="props.row.delete"           
            class="ml-3"
            :disabled="disabledItems"
            size="is-small"
          />
        </b-table-column>
      </b-table>

      <div
        class=" columns is-multiline is-mobile is-variable is-1 mt-4"
      > 
        <div class="column is-12">
          <b-button
            type="is-primary"
            class="mr-3"
            :disabled="update_loading || disabledItems"
            :loading="update_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
          <b-button
            :disabled="update_loading"
            @click="$emit('cancel'); cancel();"
          >
            {{
              $t("cancel")
            }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    permission: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      renderComponent: true,
      myPermission: {
        label: null,
        description: null,
        roles: [],
      },
      label: {
        role: this.$i18n.tc('role',1),
        create: this.$i18n.t('create'),
        read: this.$i18n.t('view'),
        update: this.$i18n.t('edit'),
        delete: this.$i18n.t('delete'),
      },
      update_loading: false,
      disabledItems: !this.$can("create", "settings.permissions")
    };
  },
  watch: {
    permission(newVal) {
      if (newVal.id) {
        this.myPermission = {
          label: newVal.label,
          description: newVal.description,
          roles: newVal.roles.slice(),
        };
        this.disabledItems = !this.$can("update", "settings.permissions");
      } else {
        this.myPermission = {
          label: null,
          description: null,
          roles: null,
        };
        this.disabledItems = !this.$can("create", "settings.permissions");
        this.$refs.observer.reset();
      }
    }
  },
  methods: {
    async forceRerender() {
      this.renderComponent = false;
      setTimeout(this.renderComponent = true,500);
    },
    cancel() {
      this.myPermission = {
          label: null,
          description: null,
          roles: [],
          // filters: this.filters,
        };
        this.forceRerender(); 
    },
    save() {
      if (
        !this.$can("create", "settings.roles") &&
        !this.$can("update", "settings.roles")
      )
        return;
      this.update_loading = true;
      let url = "permissions";
      let method = "POST";

      let formData = {
        permission: this.myPermission.label,
        description: this.myPermission.description,
      };

      if (this.permission.id) {
        url += `/${this.permission.id}`;
        method = "PUT";
        let roles = this.myPermission.roles.map(({label, name, ...arr}) => arr );
        formData['roles'] = roles;
      }

      this.$axios({
        method: method,
        url: url,
        data: formData
      })
        .then(({ data }) => {
          if (this.permission.id) {
            this.$emit("updated", data);
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else {
            this.$emit("created", data);
            this.$root.notify(this.$t("successfully_created"), "is-success");
          }
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.update_loading = false));
    }
  }
};
</script>

