<template>
  <Page
    has-actions
    :subtitle="`${items.length} ${$tc('result', items.length <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex">
          <b-button
            v-if="$device.mobile"
            style="z-index: 9"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon
              icon="magnify"
              size="is-small"
            />
          </b-button>
          <b-input
            ref="search"
            v-model="search"
            :placeholder="$t('search')"
            icon="magnify"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            rounded
            @blur="hideMobile()"
          />
        </div>
      </div>
    </div>
    <Card
      v-if="$can('read', 'settings.permissions')"
      content-class="c-table"
    >
      <b-tabs      
        v-model="activeTab"
        expanded
        class="paddingless paddingless-top"
      >
        <b-tab-item
          :label="$tc('role',2)"          
          value="role"
        >
          <div class="columns is-mobile is-multiline is-variable is-5 m-0">
            <div
              class="column is-8-tablet is-12-mobile p-0 pb-3 column-separator-right"
            >
              <b-table
                :loading="loading"
                :data="items"
                :total="items.length"
                detail-key="id"
                hoverable
                detailed
                custom-detail-row
                show-detail-icon
                :has-detailed-visible="(row, index) => row.permissions.length"
              >
                <b-table-column
                  v-slot="props"
                  field="id"
                  width="40"
                  :label="this.$i18n.t('id')"
                  header-class="sticky-header"
                >
                  <a
                    :id="props.row.id"
                    href="javascript:;"
                    class="is-clickable"
                    @click="openEditRole(props.row)"
                  >
                    {{ props.row.id }}
                  </a>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="label"
                  :label="this.$i18n.tc('role', 1)"
                  header-class="sticky-header"
                >
                  <b-tooltip
                    position="is-right"
                    :label="props.row.description"
                    type="is-primary is-light"
                    multilined
                  >
                    {{ props.row.label }}
                  </b-tooltip>
                </b-table-column>

                <b-table-column
                  v-slot="props"
                  field="agent"
                  :label="this.$i18n.tc('agent', 2)"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  {{ props.row.count }}
                </b-table-column>

                <b-table-column
                  v-slot="props"
                  field="create"
                  :label="this.$i18n.t('create')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  <b-checkbox
                    :indeterminate="hasIndeterminate(props.row, 'create')"
                    :value="getRoleValue(props.row, 'create')"
                    :disabled="
                      !$can('update', 'settings.permissions') ||
                        props.row.permissions.length == 0
                    "
                    class="ml-3"
                    size="is-small"
                    @input="val => selectItem(val, 'create', props.index)"
                  />
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="read"
                  :label="this.$i18n.t('view')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  <b-checkbox
                    :indeterminate="hasIndeterminate(props.row, 'read')"
                    :value="getRoleValue(props.row, 'read')"
                    :disabled="
                      !$can('update', 'settings.permissions') ||
                        props.row.permissions.length == 0
                    "
                    class="ml-3"
                    size="is-small"
                    @input="val => selectItem(val, 'read', props.index)"
                  />
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="update"
                  :label="this.$i18n.t('edit')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  <b-checkbox
                    :indeterminate="hasIndeterminate(props.row, 'update')"
                    :value="getRoleValue(props.row, 'update')"
                    :disabled="
                      !$can('update', 'settings.permissions') ||
                        props.row.permissions.length == 0
                    "
                    class="ml-3"
                    size="is-small"
                    @input="val => selectItem(val, 'update', props.index)"
                  />
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="delete"
                  :label="this.$i18n.t('delete')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  <b-checkbox
                    :indeterminate="hasIndeterminate(props.row, 'delete')"
                    :value="getRoleValue(props.row, 'delete')"
                    :disabled="
                      !$can('update', 'settings.permissions') ||
                        props.row.permissions.length == 0
                    "
                    class="ml-3"
                    size="is-small"
                    @input="val => selectItem(val, 'delete', props.index)"
                  />
                </b-table-column>

                <template
                  slot="detail"
                  slot-scope="props"
                >
                  <tr
                    v-for="item in props.row.permissions"
                    :key="item.label"
                    class="has-text-grey"
                  >
                    <td />
                    <td />
                    <td class="pl-5">
                      <b-tooltip
                        position="is-right"
                        :label="item.description"
                        type="is-primary is-light"
                        multilined
                      >
                        {{ item.label }}
                      </b-tooltip>
                    </td>
                    <td />
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.create"
                        :disabled="!$can('update', 'settings.permissions')"
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.read"
                        :disabled="!$can('update', 'settings.permissions')"
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.update"
                        :disabled="!$can('update', 'settings.permissions')"
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.delete"
                        :disabled="!$can('update', 'settings.permissions')"
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                  </tr>
                </template>
              </b-table>
              <div class="pagination-container pb-0">
                <b-button
                  type="is-primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="savePermissions"
                >
                  {{
                    $t("save")
                  }}
                </b-button>
              </div>
            </div>
            <div
              id="form"
              class="column is-4-tablet is-12-mobile pt-4"
              :class="{ 'px-0': $device.mobile }"
            >
              <div class="sticky">
                <p
                  class="is-size-5 pb-5 is-flex is-justify-content-space-between is-align-items-baseline"
                >
                  <b>{{ editRole.id ? $t("edit") : $t("create_role") }}</b>
                  <!-- <span
                v-if="editRole.id && editRole.removable"
                class="is-clickable is-size-7"
                @click="confirmDeleting(editRole.id)"
              >
                <b-icon icon="delete" size="is-small" type="is-danger" />
                {{ $t("delete") }}
              </span> -->
                </p>
                <RoleForm
                  :role="editRole"
                  @updated="updateItem"
                  @created="addItem"
                  @cancel="editRole = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>

        <!-- permissions -->
        <b-tab-item
          :label="$tc('permission',2)"          
          value="permission"
        >
          <div class="columns is-mobile is-multiline is-variable is-5 m-0">
            <div
              class="column is-7-tablet is-12-mobile p-0 pb-3 column-separator-right"
            >
              <b-table
                :loading="loading"
                :data="permissions"
                :total="permissions.length"          
                hoverable
                detailed
                custom-detail-row
                show-detail-icon
                :has-detailed-visible="(row, index) => row.agents.length"
              >
                <b-table-column
                  v-slot="props"
                  field="id"
                  width="40"
                  :label="this.$i18n.t('id')"
                  header-class="sticky-header"
                >
                  <a
                    :id="props.row.id"
                    href="javascript:;"
                    class="is-clickable"
                    @click="openEditPermission(props.row)"
                  >
                    {{ props.row.id }}
                  </a>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  field="label"
                  :label="this.$i18n.tc('permission', 1)"
                  header-class="sticky-header"
                >
                  <b-tooltip
                    position="is-right"
                    :label="props.row.description"
                    type="is-primary is-light"
                    multilined
                  >
                    {{ props.row.label }}
                  </b-tooltip>
                </b-table-column>

                <b-table-column                 
                  field="create"
                  :label="this.$i18n.t('create')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  -
                </b-table-column>
                <b-table-column
                  field="read"
                  :label="this.$i18n.t('view')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  -
                </b-table-column>
                <b-table-column
                  field="update"
                  :label="this.$i18n.t('edit')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  -
                </b-table-column>
                <b-table-column
                  field="delete"
                  :label="this.$i18n.t('delete')"
                  width="80"
                  centered
                  header-class="sticky-header"
                >
                  -
                </b-table-column>

                <template
                  slot="detail"
                  slot-scope="props"
                >
                  <tr
                    v-for="item in props.row.agents"
                    :key="item.id"
                    class="has-text-grey"
                  >
                    <td />
                    <td />
                    <td class="pl-5">
                      <router-link                        
                        :to="`/settings/agents/${item.id}`"
                      >
                        {{ item.fname }} {{ item.lname }}
                      </router-link>
                      <span v-if="item.custom"> - {{ $t('custom') }}</span>
                      <span v-else> - {{ item.role.label }}</span>
                    </td>
                    
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.create"
                        disabled
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.read"
                        disabled
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.update"
                        disabled
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                    <td class="has-text-centered">
                      <b-checkbox
                        v-model="item.delete"
                        disabled
                        class="ml-3"
                        size="is-small"
                      />
                    </td>
                  </tr>
                </template> 
              </b-table>
            </div>
            <!-- edit permission -->
            <div
              id="permissionForm"
              class="column is-5-tablet is-12-mobile pt-4"
              :class="{ 'px-0': $device.mobile }"
            >
              <div class="sticky">
                <p
                  class="is-size-5 pb-5 is-flex is-justify-content-space-between is-align-items-baseline"
                >
                  <b>{{ editPermission.id ? $t("edit") : $t("create_permission") }}</b>
                </p>
                <PermissionForm
                  :permission="editPermission"
                  @updated="updatePermission"
                  @created="addPermission"
                  @cancel="cancelPermission()" 
                />
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </Card>
  </Page>
</template>

<script>
import RoleForm from "@/components/forms/Role";
import PermissionForm from "@/components/forms/Permission";

export default {
  components: {
    RoleForm,
    PermissionForm
  },
  data() {
    return {
      rowsPerPage: [15, 30],
      pagination: { perPage: 15, page: 1 },
      items: [],
      permissions: [],
      loading: true,
      searchFocused: false,
      search: "",
      activeTab: 0,
      editRole: {},
      editPermission: {},
    };
  },
    watch: {
    activeTab() {
      if (this.activeTab === 1)
      this.getPermissions();
      else if (this.activeTab === 0 )
      this.getRoles();

    },
  },
  created() {
    this.updateTitle(this.$i18n.tc("role", 2)+" & "+this.$i18n.tc("permission", 2));
    this.getRoles();
    // this.editFilters = this.filters;
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    confirmDeleting(agent_id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.delete(agent_id)
      });
    },
    delete(role_id) {
      this.$axios
        .delete("roles/" + role_id)
        .then(() => {})
        .catch(e => this.clientError(e));
    },
    hasIndeterminate(item, action) {
      let hasTrue = false;
      let hasFalse = false;

      for (let i = 0; i < item.permissions.length; i++) {
        const per = item.permissions[i][action];
        if (per) hasTrue = true;
        else hasFalse = true;
      }

      return hasTrue && hasFalse;
    },
    getRoleValue(item, action) {
      let selected = true;

      for (let i = 0; i < item.permissions.length; i++) {
        const per = item.permissions[i][action];

        if (!per) {
          selected = false;
          break;
        }
      }

      return selected;
    },
    selectItem(value, action, index) {
      let item = { ...this.items[index] };

      for (let i = 0; i < item.permissions.length; i++) {
        item.permissions[i][action] = value;
      }

      this.items[index] = item;
    },
    openEditRole(role) {
      this.editRole = role;
      if (this.$device.mobile) {
        this.$scrollTo("#form");
      }
    },
    openEditPermission(permission) {
      this.editPermission = permission;
      // this.editFilters = this.filters;
      if (this.$device.mobile) {
        this.$scrollTo("#permissionForm");
      }
    },
    addPermission(item) {
      this.permissions.unshift(item);
      this.editPermission = item;
    },
    updatePermission(item) {
      let index = this.permissions.findIndex(r => r.id == item.id);
      this.permissions[index].label = item.label;
      this.permissions[index].description = item.description;
      this.permissions[index].agents = item.agents;
      // this.permissions[index].roles = item.roles;
      this.editPermission = {};
    },
    cancelPermission() {
      this.editPermission = {};
      this.editPermmission.roles = [];
      // this.editFilters = this.filters;
    },
    updateItem(item) {
      let index = this.items.findIndex(r => r.id == item.id);
      this.items[index].label = item.label;
      this.items[index].description = item.description;
      this.editRole = {};
    },
    addItem(item) {
      this.items.unshift(item);
      this.editRole = {};
    },
    savePermissions() {
      if (!this.$can("update", "settings.permissions")) return;
      this.loading = true;
      let data = {
        roles: this.items
          .filter(i => i.permissions.length != 0)
          .reduce((arr, i) => {
            let obj = { id: i.id };
            obj.permissions = i.permissions.reduce(
              (arr2, p) => [
                ...arr2,
                {
                  id: p.id,
                  create: p.create,
                  read: p.read,
                  update: p.update,
                  delete: p.delete
                }
              ],
              []
            );

            return [...arr, obj];
          }, [])
      };
      this.$axios
        .put(`/roles/permissions`, data)
        .then(() => {
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
    getPermissions() {
      if (!this.$can("read", "settings.permissions")) return;
      this.loading = true;
      this.$axios
        .get(`/permissions`)
        .then(({ data }) => {
          this.permissions = data.list;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
    getRoles() {
      if (!this.$can("read", "settings.permissions")) return;
      this.loading = true;
      this.$axios
        .get(`/roles`)
        .then(({ data }) => {
          this.items = data.list;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
