<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile is-variable is-2">
        <div class="column is-12">
          <b-input-validation
            v-model="myRole.label"
            rules="required|min:2"
            maxlength="30"

            :label="$tc('role', 1)"
            vid="fname"
            :placeholder="placeholders.role.name"
            :disabled="disabledItems || !!role.id"
          />
        </div>
        <div class="column is-6-tablet is-12">
          <b-input-validation
            v-model="myRole.description"
            rules="required|min:2"
            maxlength="255"
            :label="$t('description')"
            rows="2"
            has-counter
            input-type="textarea"
            vid="lname"
            :placeholder="placeholders.role.display"
            :disabled="disabledItems"
          />
        </div>
        <div class="column">
          <b-button
            type="is-primary"
            class="mr-3"
            :disabled="update_loading || disabledItems"
            :loading="update_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
          <b-button
            :disabled="update_loading"
            @click="$emit('cancel')"
          >
            {{
              $t("cancel")
            }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    role: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      myRole: {
        label: null,
        description: null
      },
      update_loading: false,
      disabledItems: !this.$can("create", "settings.permissions")
    };
  },
  watch: {
    role(newVal) {
      if (newVal.id) {
        this.myRole = {
          label: newVal.label,
          description: newVal.description
        };
        this.disabledItems = !this.$can("update", "settings.permissions");
      } else {
        this.myRole = {
          label: null,
          description: null
        };
        this.disabledItems = !this.$can("create", "settings.permissions");
        this.$refs.observer.reset();
      }
    }
  },
  methods: {
    save() {
      if (
        !this.$can("create", "settings.permissions") &&
        !this.$can("update", "settings.permissions")
      )
        return;
      this.update_loading = true;
      let url = "roles";
      let method = "POST";

      if (this.role.id) {
        url += `/${this.role.id}`;
        method = "PUT";
      }

      let formData = {
        role: this.myRole.label,
        description: this.myRole.description
      };
      this.$axios({
        method: method,
        url: url,
        data: formData
      })
        .then(({ data }) => {
          if (this.role.id) {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
            this.$emit("updated", data);
          } else {
            this.$root.notify(this.$t("successfully_created"), "is-success");
            this.$emit("created", data);
          }
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.update_loading = false));
    }
  }
};
</script>

